import { selector, selectorFamily, atom } from 'recoil'

import { CurrencyPair } from 'types/currency-pair'
import { StreamingTileLocalSliver } from 'types/streaming-tile-local-sliver'

import rfqTileSlice from './rfqTiles'
import { streamingTiles } from './streamingTiles'
import { viewStateSlice } from './view'

const selectedGroupId = selector({
  key: 'tile.selectedGroupId',
  get: ({ get }) => {
    const viewState = get(viewStateSlice.selectedView)
    if (viewState === 'trading') {
      return get(streamingTiles.selectedGroupId)
    } else if (viewState === 'rfq') {
      return get(rfqTileSlice.selectedGroupId)
    }
  },
  set: ({ set, get }, newValue) => {
    const viewState = get(viewStateSlice.selectedView)
    if (viewState === 'trading') {
      set(streamingTiles.selectedGroupId, newValue)
    } else if (viewState === 'rfq') {
      set(rfqTileSlice.selectedGroupId, newValue)
    }
  },
})

const selectedGroupIds = selector<string[]>({
  key: 'tile.selectedGroupIds',
  get: ({ get }) => {
    const viewState = get(viewStateSlice.selectedView)
    if (viewState === 'trading') {
      return get(streamingTiles.groupIds)
    } else if (viewState === 'rfq') {
      return get(rfqTileSlice.groupIds)
    }
  },
})

type LocalSliverOrCurrencyPair =
  | StreamingTileLocalSliver[]
  | Array<CurrencyPair>

const groupById = selectorFamily<LocalSliverOrCurrencyPair, string>({
  key: 'tile.groupById',
  get:
    (groupId: string) =>
    ({ get }) => {
      const viewState = get(viewStateSlice.selectedView)
      if (viewState === 'trading') {
        return get(streamingTiles.groupById(groupId))
      } else if (viewState === 'rfq') {
        return get(rfqTileSlice.groupById(groupId))
      }
    },
  set:
    (groupId: string) =>
    ({ get, set }, newValue) => {
      const viewState = get(viewStateSlice.selectedView)
      if (viewState === 'trading') {
        set(streamingTiles.groupById(groupId), newValue)
      } else if (viewState === 'rfq') {
        set(rfqTileSlice.groupById(groupId), newValue)
      }
    },
})

const tileBeingDragged = atom<number>({
  key: 'TileBeingDragged',
  default: -1,
})

const tileSlice = {
  selectedGroupIds,
  selectedGroupId,
  groupById,
  tileBeingDragged,
}

export default tileSlice
