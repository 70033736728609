import {
  atomFamily,
  RecoilState,
  selectorFamily,
  RecoilValueReadOnly,
} from 'recoil'

type SymbolPair = [string, string]

type Balance = {
  amount: string
  availableAmount: string
}

interface BalanceSlice {
  balanceOfCurrencyPair: (
    arg: SymbolPair,
  ) => RecoilValueReadOnly<[Balance, Balance]>
  balances: (symbol: string) => RecoilState<Balance>
}

const balances = atomFamily<Balance, string>({
  key: 'Balance',
  default: {
    amount: '0',
    availableAmount: '0',
  },
})

const balanceOfCurrencyPair = selectorFamily<[Balance, Balance], SymbolPair>({
  key: 'BalanceOfCurrencyPair',
  get:
    (pair: SymbolPair) =>
    ({ get }) =>
      [get(balances(pair[0])), get(balances(pair[1]))],
})

const balance: BalanceSlice = {
  balanceOfCurrencyPair,
  balances,
}

export default balance
