/**
 * eqSet - return true if the set `xs` is equal to the set `xy` and
 *   false otherwise.
 *
 * Considering that the caller may create `Set` from iterables
 *   in order to call this function...
 * And considering the net operation to be on 2 iterable "inputs"...
 *
 * 1, This is efficient if both inputs are already of type `Set`.
 * 2, If only one input is `Set` but the other is iterable, then could
 *    be just as fast if it were to check which direction to operate in.
 * 3, If both inputs are iterable non-`Set` then just one could be
 *    arbitrarily picked to make a set and then covered by the 2nd case.
 *
 * TBD: take 2 iterables and do the right thing with 'is-set', maybe,
 *   `xIterable.constructor === Set`
 */
export const eqSet = <Type>(xs: Set<Type>, ys: Set<Type>): boolean =>
  xs.size === ys.size && [...xs].every((x) => ys.has(x))

/**
 * stringComparator - Return 1 of valueA is greater than valueB and 0 if their are equal
 */
export const stringComparator = (a: string, b: string) => {
  const handleNils = (a: unknown, b: unknown) => {
    if (!a) return -1
    if (!b) return 1
  }

  const handleNonStrings = (a: unknown, b: unknown) => {
    if (typeof a !== 'string') return -1
    if (typeof b !== 'string') return 1
  }

  return handleNils(a, b) ?? handleNonStrings(a, b) ?? a.localeCompare(b)
}
