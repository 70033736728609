export const blotterOrdersColumnsNames = {
  orderType: 'Type',
  date: 'Date',
  currency: 'Currency',
  amountCurrency: 'Amount Currency',
  symbol: 'Symbol',
  orderQTY: 'Quantity',
  side: 'Side',
  filledQTY: 'Fill QTY',
  totalAmount: 'Total Amount',
  averagePrice: 'Average Price',
  orderID: 'Order ID',
  price: 'Price',
  action: 'Actions',
  status: 'Status',
  progress: 'Progress',
}

export const blotterTradesColumnsNames = {
  date: 'Date',
  symbol: 'Symbol',
  quantity: 'Quantity',
  side: 'Side',
  amount: 'Amount',
  price: 'Price',
  tradeID: 'Trade ID',
  status: 'Order Status',
}
