import styled, { css } from 'styled-components'
import tw from 'twin.macro'
export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'default' | 'small'
}
export const Button = styled.button<ButtonProps>`
  ${tw`
    min-h-[40px]
    py-2
    px-4
    border-radius[4px]
    border
    border-color[transparent]
    font-ringside-narrow
    font-weight[350]
    text-[14px]
    leading-[16px]
    text-light-300
    flex
    items-center
  `}
  ${({ size }) =>
    size === 'small'
      ? tw`min-h-[unset] py-[3px] px-2 font-normal text-[12px] leading-[14px]`
      : ''}

  ${({ disabled }) =>
    disabled
      ? tw`bg-transparent font-medium text-light-400 cursor-default`
      : css`
          ${tw`hover:bg-[rgba(255, 255, 255, 0.05)] hover:text-white`}
          ${tw`focus:border focus:border-solid focus:border-light-700 focus:text-light-400`}
          ${tw`active:bg-[rgba(0, 0, 0, 0.15)] active:text-white active:border-transparent`}
        `}
`
