import {
  get as idbKeyvalGet,
  set as idbKeyvalSet,
  del as idbKeyvalDel,
} from 'idb-keyval'

import { StorageAdapter } from 'types/storage-adapter'

export const IndexedDbStorageAdapter: StorageAdapter = {
  get: idbKeyvalGet,
  set: idbKeyvalSet,
  delete: idbKeyvalDel,
}
