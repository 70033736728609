import balance from './balance'
import { blottersSlice } from './blotter'
import { customerSecurities } from './customerSecurities'
import { marketDataSnapshots } from './marketDataSnapshots'
import quickRFQ from './quickRFQ'
import { quoteReponses, quoteResponseState } from './quote'
import rfqTiles from './rfqTiles'
import { socketMeta } from './socketMeta'
import { streamingTiles } from './streamingTiles'
import tileSlice from './tiles'
import { tradeSlice } from './trade'
import { viewStateSlice } from './view'

const store = {
  customerSecurities,
  marketDataSnapshots,
  quoteReponses,
  quoteResponseState,
  socketMeta,
  streamingTiles,
  trade: tradeSlice,
  view: viewStateSlice,
  rfqTiles,
  tileSlice,
  quickRFQ,
  balance,
  blotters: blottersSlice,
}

export default store
