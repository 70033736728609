import { atomFamily } from 'recoil'

import { OrdersBlotterColumns, TradesBlotterColumns } from 'lib/blotter-columns'
import { localStorageEffect } from 'lib/local-storage-effect'
import { BlotterColumnsCategory } from 'types/blotter-columns-category'
import { Column } from 'types/column'

const getDefaultColumns = (blotterType: BlotterColumnsCategory) =>
  ['open-orders', 'orders'].includes(blotterType)
    ? [...OrdersBlotterColumns]
    : [...TradesBlotterColumns]

const columns = atomFamily<Column[], BlotterColumnsCategory>({
  key: 'blotter.columns',
  effects: (blotterColumnsCategory: BlotterColumnsCategory) => [
    localStorageEffect(
      'blotter.columns',
      () => getDefaultColumns(blotterColumnsCategory),
      blotterColumnsCategory,
    ),
  ],
})

export const blottersSlice = { columns }
