import { atom, selector, selectorFamily } from 'recoil'

import { localStorageEffect } from 'lib/local-storage-effect'
import { getDefaultRFQTilePairs } from 'lib/utils/rfqTiles'
import { CurrencyPair } from 'types/currency-pair'

type Group = Array<CurrencyPair>
type Groups = Record<string, Group>

const defaultRfqGroups = {
  Alpha: getDefaultRFQTilePairs(),
  Beta: [],
  Gamma: [],
  Delta: [],
}

const selectedGroupId = atom<string>({
  key: 'SelectedRFQTileGroup',
  effects: [localStorageEffect('SelectedRFQTileGroup', () => 'Alpha')],
})

const rfqGroups = atom<Groups>({
  key: 'RFQTileGroups',
  effects: [localStorageEffect('RFQTileGroups', () => defaultRfqGroups)],
})

const groupIds = selector<string[]>({
  key: 'RFQTileGroupIds',
  get: ({ get }) => Object.keys(get(rfqGroups)),
})

const groupById = selectorFamily<Group, string>({
  key: 'RFQTileGroupSelector',
  get:
    (groupId: string) =>
    ({ get }) =>
      get(rfqGroups)[groupId],
  set:
    (groupId: string) =>
    ({ set, get }, newValue) => {
      const groups = get(rfqGroups)
      set(rfqGroups, {
        ...groups,
        [groupId]: newValue,
      })
    },
})

type GroupPosition = { position: number; groupId: string }

const currencyPairByPosition = selectorFamily<CurrencyPair, GroupPosition>({
  key: 'RFQCurrencypairByPosition',
  get:
    ({ position, groupId }) =>
    ({ get }) =>
      get(groupById(groupId))[position],
  set:
    ({ position, groupId }) =>
    ({ set, get }, newValue: CurrencyPair) => {
      const prevGroup = get(groupById(groupId))
      const next = Array.from(prevGroup)
      next[position] = newValue
      set(groupById(groupId), next)
    },
})

const rfqTileSlice = {
  selectedGroupId,
  rfqGroups,
  groupIds,
  groupById,
  currencyPairByPosition,
}

export default rfqTileSlice
