import { atom } from 'recoil'

import { localStorageEffect } from 'lib/local-storage-effect'
import { ViewName } from 'types/view-name'

const selectedView = atom<ViewName>({
  key: 'view.selectedView',
  effects: [localStorageEffect('view.selectedView', () => 'trading')],
})

export const viewStateSlice = { selectedView }
