import { Column } from 'types/column'

import {
  blotterOrdersColumnsNames,
  blotterTradesColumnsNames,
} from './blotter-column-names'

export const OrdersBlotterColumns: Column[] = [
  { id: blotterOrdersColumnsNames.progress, show: true },
  { id: blotterOrdersColumnsNames.date, show: true },
  { id: blotterOrdersColumnsNames.status, show: true },
  { id: blotterOrdersColumnsNames.orderType, show: true },
  { id: blotterOrdersColumnsNames.symbol, show: true },
  { id: blotterOrdersColumnsNames.side, show: true },
  { id: blotterOrdersColumnsNames.currency, show: false },
  { id: blotterOrdersColumnsNames.amountCurrency, show: false },
  { id: blotterOrdersColumnsNames.orderQTY, show: true },
  { id: blotterOrdersColumnsNames.price, show: true },
  { id: blotterOrdersColumnsNames.filledQTY, show: true },
  { id: blotterOrdersColumnsNames.averagePrice, show: true },
  { id: blotterOrdersColumnsNames.totalAmount, show: true },
  { id: blotterOrdersColumnsNames.orderID, show: true },
  { id: blotterOrdersColumnsNames.action, show: true },
]

export const TradesBlotterColumns: Column[] = [
  { id: blotterTradesColumnsNames.date, show: true },
  { id: blotterTradesColumnsNames.status, show: true },
  { id: blotterTradesColumnsNames.symbol, show: true },
  { id: blotterTradesColumnsNames.side, show: true },
  { id: blotterTradesColumnsNames.quantity, show: true },
  { id: blotterTradesColumnsNames.amount, show: true },
  { id: blotterTradesColumnsNames.price, show: true },
  { id: blotterTradesColumnsNames.tradeID, show: true },
]
