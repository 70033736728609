import { StorageAdapter, StoredValue } from 'types/storage-adapter'

import { IndexedDbStorageAdapter } from './indexed-db-storage-adapter'

/**
 * localStorageEffect - provide an facility to read and write local storage,
 * compatible with the App Store. Note that it is not required that it use
 * *Local Storage*, but rather it could use any kind of client-side storage,
 * for example *Indexed DB*.
 *
 * See
 *   - https://recoiljs.org/docs/guides/atom-effects/#initialize-with-promise
 *   - https://github.com/jakearchibald/idb-keyval#readme
 */

export const localStorageEffect = <T extends StoredValue>(
  key: string,
  createDefault: () => T,
  familyParameter?: string,
  storageAdapter?: StorageAdapter,
) => {
  const resultStorageAdapter = storageAdapter ?? IndexedDbStorageAdapter

  const maybeFamilyMemberKey = [key, familyParameter].filter((x) => x).join('.')

  return ({ setSelf, onSet }) => {
    setSelf(
      resultStorageAdapter.get(maybeFamilyMemberKey).then((savedValue) => {
        return savedValue ?? createDefault()
      }),
    )

    // Subscribe to state changes and persist them
    onSet(async (newValue: T, _, isReset: boolean) =>
      isReset
        ? resultStorageAdapter.delete(maybeFamilyMemberKey)
        : resultStorageAdapter.set(maybeFamilyMemberKey, newValue),
    )
  }
}
