import { atom } from 'recoil'

import { CurrencyPair } from 'types/currency-pair'

const currencyPair = atom<CurrencyPair>({
  key: 'QuickRFQCurrencyPair',
  default: null,
})

const quickRFQ = {
  currencyPair,
}

export default quickRFQ
