import { CurrencyPair } from 'types/currency-pair'

import { DEFAULT_CURRENCY_PAIR } from './defaultCurrencyPair'

const ETH_USD: CurrencyPair = [
  { Symbol: 'ETH', Description: 'Ethereum' },
  { Symbol: 'USD', Description: 'USD Coin' },
]

export const getDefaultRFQTilePair = () => DEFAULT_CURRENCY_PAIR

export const getDefaultRFQTilePairs = () => [getDefaultRFQTilePair(), ETH_USD]
