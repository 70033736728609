import { StreamingTileLocalSliver } from 'types/streaming-tile-local-sliver'

import { DEFAULT_CURRENCY_PAIR } from './defaultCurrencyPair'

export const getDefaultStreamingTileSliver = (): StreamingTileLocalSliver => ({
  currencyPair: DEFAULT_CURRENCY_PAIR,
  amount: '',
  normalSize: '',
  sizeBuckets: [],
  volumeLadderOpen: false,
})
